'use client';
import { createContext, useContext, useState } from 'react';

export const MODALS = {
  CREATE_PROJECT_MODAL: 'showCreateProjectModal',
  CREATE_TOPIC: 'showCreateTopic',
  CREATE_KEYWORDS: 'showCreatekeywords',
  CREATE_CAMPAIGN: 'showCreateCampaign',
  CREATE_CONTENT: 'showCreateContent',
  GENERATE_TOPICS: 'showGenerateTopics',
  GENERATE_CAMPAIGNS: 'showGenerateCampaigns',
  PROJECT_MODAL: 'showProjectModal',
  DEPLOY_MODAL: 'showDeployModal',
  ENABLE_AUTHENTICATION_MODAL: 'showEnableAuthenticationModal',
  SELECT_NEW_PROJECT_TYPE: 'showSelectNewProjectType',
  UPDATE_SCHEMA_MODAL: 'showUpdateSchemaModal',
  CONNECT_INTEGRATION: 'showConnectIntegration',
  SCHEDULE_TOPIC: 'showScheduleTopic',
  DELETE_TOPIC: 'showDeleteTopic',
  DELETE_POST: 'showDeleteContent',
  AI_WRITING_MODAL: 'showAIWritingModal',
  PLAN_KEYWORDS_MODAL: 'planKeywordsModal',
  PLAN_TOPICS_MODAL: 'planTopicsModal',
  WRITE_CONTENT_MODAL: 'writeContentModal',
  CREATE_BRAND_VOICE_MODAL: 'showCreateBrandVoiceModal',
  CONNECT_WEBSITE_ACCOUNT: 'showConnectWebsiteAccount',
};

const initialState = {
  showCreateProjectModal: false,
  showCreateTopic: false,
  showCreateCampaign: false,
  showCreateContent: false,
  showGenerateTopics: false,
  showGenerateCampaigns: false,
  showProjectModal: false,
  showDeployModal: false,
  showEnableAuthenticationModal: false,
  showSelectNewProjectType: false,
  showUpdateSchemaModal: false,
  showConnectIntegration: false,
  showScheduleTopic: false,
  showDeleteTopic: false,
  showDeleteContent: false,
  showAIWritingModal: false,
  planKeywordsModal: false,
  planTopicsModal: false,
  writeContentModal: false,
  showCreateBrandVoiceModal: false,
  showConnectWebsiteAccount: false,
};

export const ModalContext = createContext({
  modals: initialState,
  showModal: () => { },
  hideModal: () => { },
});

const initialData = {
  selectedTemplate: null,
  selectedProject: null,
  selectedIntegration: null,
  integrationType: null,
  selectedTopic: null,
};

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState({
    ...initialState,
    data: initialData,
  });

  const showModal = (modal, data) => {
    const state = {
      ...initialState,
      ...modals,
      [modal]: true,
      data: {
        ...modals.data,
        ...data,
      },
    };
    console.log('state', state);
    setModals(state);
  };

  const hideModal = (modal) => {
    const state = {
      ...modals,
      [modal]: false,
      data: initialData,
    };
    setModals(state);
  };

  return (
    <ModalContext.Provider
      value={{
        modals,
        showModal,
        hideModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModals = () => useContext(ModalContext);
