import { useNavigate } from 'react-router-dom';
import {
  getAccount,
  validateProjectAccessTokenApi,
} from '../services/api.service';
import {
  clearAccessToken,
  clearProjectAccessTokenFromLocalstorage,
  getAccessToken,
  getProjectAccessTokenFromLocalstorage,
  getUserFromLocalstorage,
  saveUserToLocalstorage,
} from '../services/localstorage';
import { createContext, useState, useContext, useEffect } from 'react';

const ProjectContext = createContext({
  project: undefined,
  projectId: undefined,
  updateProject: () => {},
  projectLoaded: false,
  loadingBlock: false,
  setLoadingBlock: () => {},
});

export const ProjectProvider = ({ children }) => {
  const [project, setProject] = useState(undefined);
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [loadingBlock, setLoadingBlock] = useState(false);

  useEffect(() => {
    const projectAccessToken = getProjectAccessTokenFromLocalstorage();
    if (projectAccessToken) {
      // setProjectLoaded(true);
      validateProjectAccessTokenApi().then(({ data: response }) => {
        const project = response;
        if (project) {
          setProject(project);
        } else {
          clearProjectAccessTokenFromLocalstorage();
          window.location.href = '/';
        }
        setProjectLoaded(true);
      });
      return;
    } else {
      setProjectLoaded(true);
    }
  }, []);

  const fetchProjectData = async () => {
    const projectAccessToken = getProjectAccessTokenFromLocalstorage();
    if (projectAccessToken) {
      // setProjectLoaded(true);
      validateProjectAccessTokenApi().then(({ data: response }) => {
        const project = response;
        if (project) {
          setProject(project);
        } else {
          clearProjectAccessTokenFromLocalstorage();
          window.location.href = '/';
        }
        setProjectLoaded(true);
      });
      return;
    } else {
      setProjectLoaded(true);
    }
  }

  const updateProject = (project) => {
    // saveUserToLocalstorage(user);
    setProject(project);
  };

  const refetchProject = () => {
    fetchProjectData();
  }

  return (
    <ProjectContext.Provider
      value={{
        project,
        projectId: project?._id,
        updateProject,
        projectLoaded,
        loadingBlock,
        setLoadingBlock,
        refetchProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProject = () => useContext(ProjectContext);
