import axios from "axios";
import { clearAccessToken, clearProjectAccessTokenFromLocalstorage, getAccessToken, getProjectAccessTokenFromLocalstorage } from "./localstorage";

axios.defaults.baseURL = 
// 'https://api.blogmate.ai';
  process.env.REACT_APP_API_SERVER || "http://localhost:3100";

axios.interceptors.request.use(
  (config) => {
    try {
      config.headers = {
        'Authorization': `Bearer ${getAccessToken()}`,
        'project-token': getProjectAccessTokenFromLocalstorage(),
      };
    } catch (error) {
      // console.log("Auth Token is not supported");
    }
    return config;
  },
  (error) => {
    console.log("error", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("error-e", error);
    if(error.response){
      const response = error.response;
      const statusCode = error.statusCode;
      const status = response.status;
      console.log("Failed", status);
      if (status === 403) {
        console.log("Failed with:", statusCode);
        // clearAccessToken();
        // clearProjectAccessTokenFromLocalstorage();
        // window.location.href = '/auth/login?loggedout=true';
      }
      if (status >= 400) {
        throw response.data;
      }
    }
  }
);

export default axios;